import MainLayout from "../../MainLayout";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import Text from "../../../components/Text";
import { useEffect, useState } from "react";
import { getVideos } from "../../../api/services/Video";
import { tGetVideosApiRes } from "../../../resources/types/api/services/tVideo";
import Card from "../../../components/Card";
import styles from "./styles.module.css";
import { refreshToken } from "../../../utils/authFuncs";
// import "@dotlottie/player-component";
import { ReactComponent as NoDataSVG } from "../../../resources/assets/svg/no-data.svg";
import { colors } from "../../../resources/theme/colors";

const Gallery = () => {
  const navigate = useNavigate();
  const { signOut, user } = useAuthenticator((context) => [context.user]);
  const [data, setData] = useState<tGetVideosApiRes>([]);
  const [loading, setLoading] = useState(true);
  const onLogout = () => {
    signOut();
    setTimeout(() => navigate("/"), 1000);
  };
  const getData = () => {
    setLoading(true);
    refreshToken()
      .then(() => getVideos())
      .then((res) => res && setData(res))
      .catch((e) => e)
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => getData(), []);
  return (
    <MainLayout
      tabHeader
      onLogoutPress={onLogout}
      onPlusPress={() => navigate("/qrReader")}
    >
      <Text className={styles.title}>Gallery</Text>
      {loading ? (
        // <dotlottie-player
        //   src={require("../../../resources/assets/lottie/circle-loader.lottie")}
        //   autoplay
        //   loop
        //   style={{
        //     height: "20%",
        //     width: "20%",
        //     marginLeft: "40%",
        //     marginTop: "50%",
        //   }}
        // />
        <div className={styles.middle}>
          <Text className={styles.loading} style={{ color: colors.primary }}>
            Loading...
          </Text>
        </div>
      ) : data.length === 0 ? (
        <div className={styles.middle}>
          <NoDataSVG className={styles.image} />
        </div>
      ) : (
        <div className={styles.grid_container}>
          {data.map((item, index) => (
            <Card
              key={index}
              {...item}
              onPress={() =>
                navigate(
                  `/player?source=${item.url}&Design=${item.design}&recipient=${item.recipient}&isBack=true`
                )
              }
            />
          ))}
        </div>
      )}
    </MainLayout>
  );
};
export default Gallery;
