import MainLayout from "../../MainLayout";
import { ReactComponent as NotFoundSVG } from "../../../resources/assets/svg/not-found.svg";
import styles from "./styles.module.css";
const NotFoundError = () => {
  return (
    <MainLayout logoHeader>
      <div className={styles.middle}>
        <NotFoundSVG className={styles.image} />
      </div>
    </MainLayout>
  );
};
export default NotFoundError;
