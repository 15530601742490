import { useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { tQRCodeItem } from "../../../resources/types/items/tQRCodeItem";
import { addQrCodeLocalDB } from "../../../utils/qrCodeFuncs";
import { getQueryParam } from "../../../utils/url";
import { config } from "../../../config";
import styles from "./styles.module.css";
import { ReactComponent as LogoSVG } from "../../../resources/assets/svg/logo.svg";
import MainLayout from "../../MainLayout";
import Text from "../../../components/Text";

const Splash = () => {
  const [params, setParams] = useState<tQRCodeItem>({ QRID: "", Design: "" });
  const navigate = useNavigate();
  const { authStatus } = useAuthenticator((context) => [context?.authStatus]);

  const nextRoute = () => {
    const code = getQueryParam("QRID");
    const design = getQueryParam("Design");
    config.DEBUG && console.log("Getting this code from URL:", code, design);
    if (code && design) {
      setParams({ QRID: code, Design: design });
      addQrCodeLocalDB({ QRID: code, Design: design });
      if (authStatus === "authenticated") {
        navigate("/occasion");
      } else if (authStatus === "unauthenticated") {
        navigate("/welcome");
      }
    } else {
      if (authStatus === "authenticated") {
        navigate("/gallery");
      } else if (authStatus === "unauthenticated") {
        navigate("/auth");
      }
    }
  };

  useEffect(() => {
    setTimeout(() => nextRoute(), 1000);
  }, [authStatus]);

  return (
    <MainLayout>
      <div className={styles.container}>
        <LogoSVG className={styles.logo} />
        <Text className={styles.label}>Your wishes their forever memory.</Text>
      </div>
    </MainLayout>
  );
};
export default Splash;
