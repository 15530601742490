import { tAwsExports } from "./resources/types/configs/tAwsConfig";
import { Amplify } from "aws-amplify";
export const currentConfig = Amplify.getConfig();
export const awsExports: tAwsExports = {
  REGION: "ap-southeast-2",
  USER_POOL_ID: "ap-southeast-2_I7h8nWt5O",
  USER_POOL_APP_CLIENT_ID: "4br5s4km2b1q50f394fnfkigqu",
  IDENTITY_POOL_ID: "ap-southeast-2:126b1612-5084-4c7a-915e-df412f02e84b",
  ALLOW_GUEST_ACCESS: true,
  SIGN_UP_VERIFICATION_METHOD: "code",
  DOMAIN: "auth.qrgreet.com",
  // DOMAIN: "qrgreet.auth.ap-southeast-2.amazoncognito.com",
  SCOPES: [
    // 'phone',
    // 'email',
    // 'profile',
    "openid",
    "aws.cognito.signin.user.admin",
  ],
  REDIRECT_SIGN_IN: ["https://app.qrgreet.com/", "http://localhost:3000/"],
  REDIRECT_SIGN_OUT: ["https://app.qrgreet.com/", "http://localhost:3000/"],
  RESPONSE_TYPE: "token",
  BUCKET: "input.qrgreet.com",
};
