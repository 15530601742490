import { BrowserRouter, Routes, Route } from "react-router-dom";
import Gallery from "../pages/content/Gallery";
import Occasion from "../pages/content/Occasion";
import VideoPlayer from "../pages/content/VideoPlayer";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Auth from "../pages/auth/Authenticator";
import NotFoundError from "../pages/error/NotFoundError";
import VideoUpload from "../pages/content/VideoUpload";
import Welcome from "../pages/start/Welcome";
import TermsConditions from "../pages/content/TermsConditions";
import Splash from "../pages/start/Splash";
import Success from "../pages/content/Success";
import Review from "../pages/content/Review";
import QrReader from "../pages/content/QrReader";
import { fetchAuthSession } from "@aws-amplify/auth";
import { useEffect } from "react";
import { config } from "../config";
import { refreshToken } from "../utils/authFuncs";
// export type tPageRoute = {
//   id: string;
//   path: string;
//   element: any;
// };
const Router = () => {
  const { authStatus } = useAuthenticator((context) => [context?.authStatus]);
  const getToken = async () => {
    refreshToken()
      .then(
        () =>
          config.DEBUG &&
          console.log(
            "Token in Router from Global Var:",
            JSON.stringify(global.tokenInfo)
          )
      )
      .catch((e) => e);
  };
  useEffect(() => {
    authStatus === "authenticated" && getToken();
    config.DEBUG &&
      console.log("------------------User Status in Router", authStatus);
  }, [authStatus]);
  const privatePages = [
    {
      id: "0",
      path: "/gallery",
      element: <Gallery />,
    },
    {
      id: "1",
      path: "/qrReader",
      element: <QrReader />,
    },
  ];
  const publicPages = [
    {
      id: "00",
      path: "/",
      element: <Splash />,
    },
    {
      id: "11",
      path: "/occasion",
      element: <Occasion />,
    },
    {
      id: "33",
      path: "/review",
      element: <Review />,
    },
    {
      id: "44",
      path: "/upload",
      element: <VideoUpload />,
    },
    {
      id: "55",
      path: "/terms",
      element: <TermsConditions />,
    },
    {
      id: "66",
      path: "/success",
      element: <Success />,
    },
    {
      id: "77",
      path: "/player",
      element: <VideoPlayer />,
    },
    {
      id: "88",
      path: "*",
      element: <NotFoundError />,
    },
  ];
  const welcomePages = [
    {
      id: "000",
      path: "/welcome",
      element: <Welcome />,
    },
    {
      id: "111",
      path: "/auth",
      element: <Auth />,
    },
  ];

  return (
    <BrowserRouter>
      <Routes>
        {publicPages.map((page) => (
          <Route
            key={page.id}
            path={page.path}
            element={page.element}
            index={page.path == "/"}
          />
        ))}
        {authStatus === "authenticated" &&
          privatePages.map((page) => (
            <Route key={page.id} path={page.path} element={page.element} />
          ))}
        {authStatus === "unauthenticated" &&
          welcomePages.map((page) => (
            <Route key={page.id} path={page.path} element={page.element} />
          ))}
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
