import { tProgressBarProps } from "../../resources/types/components/tProgressBarProps";
import "./styles.css";
const ProgressBar = ({ value }: tProgressBarProps) => {
  return (
    <div className="bar">
      <div className="bar active" style={{ width: `${value}%` }} />
    </div>
  );
};
export default ProgressBar;
