import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import { Authenticator, ThemeProvider } from "@aws-amplify/ui-react";
import { darkTheme } from "./resources/theme/darkTheme";
import { awsExports } from "./aws-config";
import { currentConfig } from "./aws-config";
// // You can get the current config object

Amplify.configure({
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: awsExports.USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: awsExports.USER_POOL_APP_CLIENT_ID,
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: awsExports.IDENTITY_POOL_ID,
      allowGuestAccess: awsExports.ALLOW_GUEST_ACCESS,
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      // signUpVerificationMethod: awsExports.SIGN_UP_VERIFICATION_METHOD, // 'code' | 'link'
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: awsExports.DOMAIN,
          scopes: awsExports.SCOPES,
          redirectSignIn: awsExports.REDIRECT_SIGN_IN,
          redirectSignOut: awsExports.REDIRECT_SIGN_OUT,
          responseType: awsExports.RESPONSE_TYPE, // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },
    },
  },
  Storage: {
    S3: {
      bucket: awsExports.BUCKET,
      region: awsExports.REGION,
    },
  },
  // ...currentConfig,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
global.tokenInfo = {
  idToken: "",
  accessToken: "",
};
root.render(
  <ThemeProvider theme={darkTheme} colorMode={"dark"}>
    <Authenticator.Provider>
      <App />
    </Authenticator.Provider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
