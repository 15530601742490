import { useEffect, useState } from "react";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { Hub } from "aws-amplify/utils";
import "@aws-amplify/ui-react/styles.css";
import { useNavigate } from "react-router-dom";
import { getQrCodeLocalDB } from "../../../utils/qrCodeFuncs";
import MainLayout from "../../MainLayout";
import ConfirmSignUp from "../ConfirmSignUp";

const Auth = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<unknown>(null);
  const [customState, setCustomState] = useState<string | null>(null);
  const { authStatus } = useAuthenticator((context) => [context?.authStatus]);

  const services = {
    // async handleSignIn(formData: any) {
    //   // return Amplify.Auth.signIn(formData.username, formData.password);
    // },
    // async handleSignUp(input: SignUpInput) {
    //   // custom username and email
    //   const { username, password, options } = input;
    //   console.log("noti",options?.userAttributes?.receive_notification);
    //   return signUp({
    //     username,
    //     password,
    //     options: {
    //       ...input.options,
    //       userAttributes: {
    //         receive_notification: options?.userAttributes?.receive_notification
    //       },
    //     },
    //   });
    // },
    // async validateCustomSignUp(formData: any) {
    //   if (!formData.acknowledgement) {
    //     return {
    //       acknowledgement: 'You must agree to the Terms and Conditions',
    //     };
    //   }
    //     if (!formData.given_name) {
    //       return {
    //         given_name: 'First Name is required',
    //       };
    //     }
    //     if (!formData.family_name) {
    //       return {
    //         family_name: 'Last Name is required',
    //       };
    //     }
    //     if (!formData.email) {
    //       return {
    //         email: 'Email is required',
    //       };
    //     }
    //   },
    // },
  };

  useEffect(() => {
    let localCode = getQrCodeLocalDB();
    const unsubscribe = Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signedIn":
          if (localCode) navigate("/occasion");
          else navigate("/gallery");
          break;
        case "signInWithRedirect":
          break;
        case "signInWithRedirect_failure":
          console.log("An error has ocurred during the Oauth flow.");
          setError("An error has ocurred during the Oauth flow.");
          break;
        case "customOAuthState":
          setCustomState(payload.data);
          break;
      }
    });

    return unsubscribe;
  }, [authStatus]);
  const formFields = {
    forgotPassword: {
      username: {
        label: "Email",
        placeholder: "Enter your email",
      },
    },
    signIn: {
      username: {
        label: "Email",
        placeholder: "Enter your email",
        isRequired: true,
        order: 1,
      },
    },
    signUp: {
      username: {
        label: "Email",
        placeholder: "Enter your email",
        order: 1,
      },
      given_name: {
        label: "First name",
        placeholder: "Enter your first name",
        order: 2,
      },
      family_name: {
        label: "Last name",
        placeholder: "Enter your last name",
        order: 3,
      },
      password: {
        label: "Password",
        placeholder: "Enter your password",
        order: 4,
      },
      confirm_password: {
        label: "Confirm Password",
        placeholder: "Please confirm your password",
        order: 5,
      },
      phone_number: {
        label: "Phone Number",
        placeholder: "Enter your phone number",
        order: 6,
      },
    },
  };
  return (
    <MainLayout backHeader onBackPress={() => navigate(-1)} topPadding>
      <Authenticator
        services={services}
        formFields={formFields}
        components={{
          SignIn: SignIn,
          SignUp: SignUp,
          ConfirmSignUp: ConfirmSignUp,
        }}
      />
    </MainLayout>
  );
};
export default Auth;
