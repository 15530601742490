import { colors } from "./colors";
import { tTheme } from "../types/theme/tTheme";
export const darkTheme: tTheme = {
  name: "dark-theme",
  primary: colors.primary,
  secondary: colors.secondary,
  black: colors.black,
  white: colors.white,
  red: colors.red,
  homeBackground: colors.black,
  button: { background: colors.primary },
  overrides: [
    {
      colorMode: "dark",
      primaryColor: colors.primary,
      secondaryColor: colors.secondary,
      cssText: colors.white,
      tokens: {
        colors: {
          background: {
            primary: { value: colors.black },
          },
          border: {
            primary: { value: colors.secondary },
            // secondary: { value: colors.secondary },
            // tertiary: { value: colors.primary },
          },
          font: {
            primary: { value: colors.white },
            secondary: { value: colors.primary },
            tertiary: { value: "green" },
          },
        },
        components: {
          rating: {
            filled: {
              color: { value: "red" },
            },
            empty: {
              color: { value: "blue" },
            },
          },
          button: {
            borderRadius: { value: "6px" },
            // this will affect the font weight of all button variants
            fontWeight: { value: "600" },
            backgroundColor: { value: "transparent" },
            borderColor: { value: colors.secondary },
            color: { value: colors.white },
            _focus: {
              borderColor: { value: colors.white },
            },
            // style the primary variation
            primary: {
              backgroundColor: { value: colors.primary },
              _hover: {
                backgroundColor: { value: colors.primary },
              },
              _focus: {
                backgroundColor: { value: colors.primary },
                borderColor: { value: colors.primary },
              },
              _active: {
                backgroundColor: { value: colors.primary },
                borderColor: { value: "pink" },
              },
              _disabled: {
                backgroundColor: { value: colors.variant_primary },
                borderColor: { value: "transparent" },
              },
              error: {
                borderColor: { value: colors.blue },
                backgroundColor: { value: "green" },
                color: { value: "yellow" },
                _hover: {
                  backgroundColor: { value: "purple" },
                },
                _focus: {
                  backgroundColor: { value: "blue" },
                },
                _active: {
                  backgroundColor: { value: "pink" },
                },
              },
            },
            link: {
              color: { value: colors.white },
              backgroundColor: { value: "transparent" },
              _hover: {
                backgroundColor: { value: "transparent" },
                color: { value: colors.primary },
                borderColor: { value: "transparent" },
              },
              _focus: {
                backgroundColor: { value: "transparent" },
                borderColor: { value: "transparent" },
                color: { value: colors.primary },
              },
              _active: {
                backgroundColor: { value: "transparent" },
                borderColor: { value: "transparent" },
                color: { value: colors.primary },
              },
              _disabled: {
                backgroundColor: { value: colors.variant_primary },
                borderColor: { value: colors.variant_primary },
              },
              error: {
                backgroundColor: { value: "green" },
                color: { value: "blue" },
                _hover: {
                  backgroundColor: { value: "red" },
                },
                _focus: {
                  backgroundColor: { value: "blue" },
                },
                _active: {
                  backgroundColor: { value: "pink" },
                },
              },
            },
          },
          authenticator: {
            router: { borderWidth: { value: "1px" } },
          },
          fieldcontrol: {
            borderColor: { value: colors.secondary },
            borderRadius: { value: "6px" },
            color: { value: colors.white },
            _active: {
              borderColor: { value: "red" },
            },
            _focus: {
              borderColor: { value: colors.white },
            },
          },
          passwordfield: {
            button: {
              primary: {
                backgroundColor: { value: "red" },
              },
              borderWidth: { value: "0px" },
              backgroundColor: { value: "red" },
              borderRadius: { value: "0 px" },
              color: { value: colors.secondary },
              _hover: {
                backgroundColor: { value: "transparent" },
                color: { value: colors.white },
                borderColor: { value: colors.secondary },
              },
              _active: {
                backgroundColor: { value: "transparent" },
                color: { value: colors.white },
                borderColor: { value: colors.white },
              },
              _focus: {
                color: { value: colors.white },
                borderColor: { value: colors.white },
              },
              _error: {
                backgroundColor: { value: "orange" },
                _hover: { backgroundColor: { value: "lavender" } },
                _focus: { borderColor: { value: "yellow" } },
                _active: { borderColor: { value: "white" } },
              },
            },
          },
          tabs: {
            borderColor: { value: colors.red },
            item: {
              color: { value: colors.secondary },
              fontSize: { value: "1.2rem" },
              fontWeight: { value: "400" },
              borderColor: { value: colors.secondary },
              _hover: {
                color: { value: colors.white },
              },
              _focus: {
                color: { value: colors.primary },
                fontWeight: { value: "700" },
              },
              _active: {
                backgroundColor: { value: "transparent" },
                color: { value: colors.primary },
                borderColor: { value: colors.primary },
              },
              _disabled: {
                color: { value: "blue" },
                backgroundColor: { value: "transparent" },
                borderColor: { value: "{colors.primary.80}" },
              },
            },
          },
        },
      },
    },
  ],
};
