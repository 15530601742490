import { config } from "../../../config";
import { tGetVideosApiRes, tReviewVideoApiReq } from "../../../resources/types/api/services/tVideo";
import { getVideosApi, reviewVideoApi } from "./http";

export const reviewVideo = async ({ QRID }: tReviewVideoApiReq) => {
  try {
    const response = await reviewVideoApi({ QRID });
    config.DEBUG && console.log("Response of Review Video: ", response);
  } catch (error) {
    config.DEBUG &&
      console.error("Error is Catched During Review Video:", error);
  }
};
export const getVideos = async () => {
  console.log("*****************")
  try {
    const response : tGetVideosApiRes = await getVideosApi();
    config.DEBUG && console.log("Response of Get Videos: ", response);
    return response;
  } catch (error) {
    config.DEBUG && console.error("Error is Catched During Get Videos:", error);
  }
};
