import {
  tGetVideosApiRes,
  tReviewVideoApiReq,
} from "../../../resources/types/api/services/tVideo";
import { api } from "../../keys";

export const reviewVideoApi = (args: tReviewVideoApiReq): Promise<Response> => {
  return new Promise((resolve, reject) => {
    fetch(`${api.REVIEW}${args.QRID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (response.ok) {
          resolve(response.json());
        } else {
          reject(new Error(`Request failed with status: ${response.status}`));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getVideosApi = (): Promise<tGetVideosApiRes> => {
  return new Promise((resolve, reject) => {
    fetch(`${api.GALLERY}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenInfo.idToken,
      },
    })
      .then((response) => {
        if (response.ok) {
          resolve(response.json());
        } else {
          reject(new Error(`Request failed with status: ${response.status}`));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
