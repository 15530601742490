export const getVideoOrientation = (width: number, height: number) => {
  if (width > height) {
    return "landscape";
  } else if (width < height) {
    return "portrait";
  }
  //    else {
  //     return "square";
  //   }
};
