import { tQRCodeItem } from "../resources/types/items/tQRCodeItem";
import { getItemLocalDB, removeItemLocalDB, setItemLocalDB } from "./storage";
import { storage_keys } from "./storage/keys";

export const addQrCodeLocalDB = (item: tQRCodeItem) => {
  setItemLocalDB(storage_keys.QR_CODE, item);
};
export const getQrCodeLocalDB = (): tQRCodeItem => {
  return getItemLocalDB(storage_keys.QR_CODE);
};

export const clearQrCodeLocalDB = () => {
  removeItemLocalDB(storage_keys.QR_CODE);
};
