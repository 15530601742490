import MainLayout from "../../MainLayout";
import { useNavigate } from "react-router-dom";
import Text from "../../../components/Text";
import styles from "./styles.module.css";
import { TERMS } from "../../../resources/staticData/terms";
import Button from "../../../components/Button";
import { getQueryParam } from "../../../utils/url";
import { useEffect, useState } from "react";

const TermsConditions = () => {
  const navigate = useNavigate();
  const [withConfirm, setWithConfirm] = useState("false");
  useEffect(() => {
    const param = getQueryParam("withConfirm");
    if (param) setWithConfirm(param);
  }, []);
  return (
    <MainLayout backHeader onBackPress={() => navigate(-1)}>
      <div className={styles.middle}>
        <Text className={styles.title}>Terms and Conditions</Text>
        <Text className={styles.terms}>{TERMS}</Text>
      </div>
      <div className={styles.footer}>
        {withConfirm === "true" && (
          <Button
            className={styles.button}
            title={"Continue"}
            onPress={() => navigate(`/occasion`)}
          />
        )}
      </div>
    </MainLayout>
  );
};

export default TermsConditions;
