import Router from "./router";
import { createContext, useState } from "react";
export const ThemeContext = createContext("dark");
function App() {
  const [theme, setTheme] = useState("dark");
  return (
    <ThemeContext.Provider value={theme}>
      <div id={theme}>
        <Router />
      </div>
    </ThemeContext.Provider>
  );
}
export default App;
