import { useAuthenticator } from "@aws-amplify/ui-react";
import MainLayout from "../../MainLayout";
import { useNavigate } from "react-router-dom";
import Text from "../../../components/Text";
import styles from "./styles.module.css";
import { ReactComponent as SuccessSVG } from "../../../resources/assets/svg/success.svg";
import Button from "../../../components/Button";

const Success = () => {
  const navigate = useNavigate();
  const { authStatus } = useAuthenticator((context) => [context?.authStatus]);
  const Footer = () => {
    if (authStatus === "authenticated") {
      return (
        <Button
          title="Go To Gallery"
          onPress={() => navigate("/gallery")}
          className={styles.button}
        />
      );
    }
    return null;
  };
  return (
    <MainLayout logoHeader>
      <div className={styles.middle}>
        <SuccessSVG className={styles.success} />
        <Text className={styles.title}>
          Your greeting has been <p>saved on QR successfully!</p>
        </Text>
        <Text className={styles.desc}>
          To make an archive for your greetings and receive a notifications once
          your QR is read make your account
        </Text>
      </div>
      <Footer />
    </MainLayout>
  );
};
export default Success;
