import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import MainLayout from "../../MainLayout";
import Text from "../../../components/Text";
import styles from "./styles.module.css";
import { ReactComponent as CandleLogoSVG } from "../../../resources/assets/svg/candle_logo.svg";
import { colors } from "../../../resources/theme/colors";

const Welcome = () => {
  const navigate = useNavigate();
  const signInWithGuest = () => {
    navigate(`/terms?withConfirm=true`);
  };
  const signInWithUser = () => {
    navigate(`/auth`, { state: { authState: "signIn" } });
  };
  const signUp = () => {
    navigate("/auth", { state: { authState: "signUp" } });
  };
  return (
    <MainLayout logoHeader>
      <div className={styles.middle}>
        <CandleLogoSVG className={styles.gift} />
        <Text className={styles.title}>Welcome!</Text>
        <Text className={styles.description}>
          Step into the world of QR GREET where each wish becomes a priceless
          memory
        </Text>
        <div className={styles.button_container}>
          <Button
            className={styles.login}
            title={"LOG IN"}
            onPress={signInWithUser}
          />
          <Button
            className={styles.guest}
            title={"USE AS GUEST"}
            onPress={signInWithGuest}
          />
        </div>
        <div className={styles.sign_up_container} onClick={signUp}>
          <Text className={styles.text_footer}>Don't Have An Account?</Text>
          <Text
            className={styles.text_footer}
            style={{ color: colors.primary }}
          >
            {" "}
            SIGN UP
          </Text>
        </div>
      </div>
    </MainLayout>
  );
};
export default Welcome;
