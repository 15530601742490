export const getDateTime = (
  utcTimestamp: string
): { date: string; time: string } => {
  // Discover user's local timezone
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert UTC timestamp to local timezone
  const date = new Date(utcTimestamp);
  const localDate = new Date(
    date.toLocaleString("en-US", { timeZone: localTimeZone })
  );

  // Format date and time
  const formattedDate = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })
    .format(localDate)
    .replace(/\//g, ".");

  const formattedTime = new Intl.DateTimeFormat("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }).format(localDate);
  return { date: formattedDate, time: formattedTime };
};
