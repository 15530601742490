import Button from "../Button";
import { ReactComponent as GoogleSVG } from "../../resources/assets/svg/google.svg";
import { ReactComponent as FacebookSVG } from "../../resources/assets/svg/facebook.svg";
import { signInWithRedirect } from "aws-amplify/auth";
import styles from "./styles.module.css";

const SocialProviders = () => {
  return (
    <div className={styles.socialContainer}>
      <Button
        style={{ height: 42 }}
        icon={<GoogleSVG width={20} height={20} />}
        className={styles.socialBtn}
        title={"Google"}
        onPress={() =>
          signInWithRedirect({ provider: "Google" })
            .then(() => {
              console.log("sign in with Google Success");
            })
            .catch((error) =>
              console.log(error, "error----------------------------")
            )
        }
      />
      <Button
        style={{ height: 42 }}
        icon={<FacebookSVG width={20} height={20} />}
        className={styles.socialBtn}
        title={"facebook"}
        onPress={() =>
          signInWithRedirect({ provider: "Facebook" })
            .then(() => {
              console.log("sign in with facebook Success");
            })
            .catch((error) =>
              console.log(error, "error----------------------------")
            )
        }
      />
    </div>
  );
};
export default SocialProviders;
