import styles from "./styles.module.css";
import { ReactComponent as LeftArrowSVG } from "../../resources/assets/svg/left-arrow.svg";
import { ReactComponent as LogoSVG } from "../../resources/assets/svg/logo.svg";
import { ReactComponent as PlusSVG } from "../../resources/assets/svg/plus.svg";
import { ReactComponent as LogoutSVG } from "../../resources/assets/svg/logout.svg";
import { tHeaderProps } from "../../resources/types/components/tHeaderProps";
import Text from "../../components/Text";
import { colors } from "../../resources/theme/colors";

const Header = ({
  showBack,
  onBackPress,
  showLogo,
  showLogout,
  onLogoutPress,
  showPlus,
  onPlusPress,
}: tHeaderProps) => {
  const textStyle = {
    color: colors.primary,
    fontSize: "0.5rem",
    fontWeight: "500",
  };
  return (
    <div className={styles.header}>
      <div className={styles.left_section}>
        {showBack && (
          <div onClick={onBackPress}>
            <LeftArrowSVG />
          </div>
        )}
        {showLogout && (
          <div className={styles.btn} onClick={onLogoutPress}>
            <LogoutSVG className={styles.logout_icon} />
            <Text style={textStyle}>Logout</Text>
          </div>
        )}
      </div>
      <div className={styles.middle_section}>
        {showLogo && <LogoSVG className={styles.logo_icon} />}
      </div>
      <div className={styles.right_section}>
        {showPlus && (
          <div className={styles.btn} onClick={onPlusPress}>
            <PlusSVG className={styles.plus_icon} />
            <Text style={textStyle}>Add New Card</Text>
          </div>
        )}
      </div>
    </div>
  );
};
export default Header;
