import { fetchAuthSession } from "@aws-amplify/auth";
import { config } from "../config";
import { getCurrentUser } from "aws-amplify/auth";
export const refreshToken = async () => {
  try {
    const session = await fetchAuthSession(); // Fetch the authentication session
    if (session.tokens === undefined) throw new Error("auth/error-get-token");
    // const { tokens } = await fetchAuthSession({ forceRefresh: true });
    console.log("------------------------");
    config.DEBUG && console.log("Token From Session:");
    config.DEBUG &&
      console.log("Access Token:", session?.tokens?.accessToken.toString());
    config.DEBUG &&
      console.log("ID Token:", session?.tokens?.idToken?.toString());
    config.DEBUG && console.log("------------------------");
    global.tokenInfo = {
      idToken: session?.tokens?.idToken?.toString() || "",
      accessToken: session?.tokens?.accessToken.toString() || "",
    };
  } catch (error) {
    config.DEBUG && console.log("Error During Refresh Token", error);
  }
};
export const getUser = async (): Promise<any> => {
  try {
    const currentUser = await getCurrentUser();
    config.DEBUG && console.log("user", currentUser);
    return currentUser;
  } catch (error) {
    console.error(
      "----------------- Error is cAtched During Get Current User",
      error,
      "-------------------"
    );
  }
};
