import { tButtonProps } from "../../resources/types/components/tButtonProps";
import Text from "../Text";
import styles from "./styles.module.css";
const Button = ({ icon, onPress, title, style, className }: tButtonProps) => {
  return (
    <div
      className={`${styles.button} ${className}`}
      onClick={onPress}
      style={style}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      <Text className={styles.label}>{title}</Text>
    </div>
  );
};
export default Button;
