import { config } from "../../../config";
import {
  tActiveQRCodeGuestApiReq,
  tActiveQRCodeUserApiReq,
} from "../../../resources/types/api/services/tCode";
import { activeQRcodeGuestApi, activeQRcodeUserApi } from "./http";

export const activeQRcodeGuest = async ({
  QRID,
  sourcemap,
  recipient,
}: tActiveQRCodeGuestApiReq) => {
  try {
    config.DEBUG &&
      console.log("Request of Active Code By User", QRID, sourcemap, recipient);
    const response = await activeQRcodeGuestApi({ QRID, sourcemap, recipient });
    config.DEBUG && console.log("Response of Active Guest QRCode: ", response);
  } catch (error) {
    config.DEBUG &&
      console.error("Error is Catched During Active Guest QR Code:", error);
  }
};
export const activeQRCodeUser = async ({
  QRID,
  sourcemap,
  recipient,
}: tActiveQRCodeUserApiReq) => {
  try {
    const response = await activeQRcodeUserApi({ QRID, sourcemap, recipient });
    config.DEBUG && console.log("Response of Active User QRCode: ", response);
  } catch (error) {
    config.DEBUG &&
      console.error("Error is Catched During Active User QR Code:", error);
  }
};
