import { config } from "../../../config";
import {
  tActiveQRCodeGuestApiReq,
  tActiveQRCodeGuestApiRes,
  tActiveQRCodeUserApiReq,
  tActiveQRCodeUserApiRes,
} from "../../../resources/types/api/services/tCode";
import { api } from "../../keys";

export const activeQRcodeGuestApi = (
  args: tActiveQRCodeGuestApiReq
): Promise<tActiveQRCodeGuestApiRes> => {
  return new Promise((resolve, reject) => {
    fetch(`${api.ACTIVE_GUEST_CODE}${args.QRID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sourcemap: args.sourcemap,
        recipient: args.recipient,
      }),
    })
      .then((response) => {
        if (response.ok) {
          resolve(response.json());
        } else {
          reject(new Error(`Request failed with status: ${response.status}`));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const activeQRcodeUserApi = (
  args: tActiveQRCodeUserApiReq
): Promise<tActiveQRCodeUserApiRes> => {
  return new Promise((resolve, reject) => {
    config.DEBUG &&
      console.log(
        "-------Token Info before active code by user:",
        global.tokenInfo,
        "-------"
      );
    fetch(`${api.ACTIVE_USER_CODE}${args.QRID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: global.tokenInfo.idToken,
      },
      body: JSON.stringify({
        sourcemap: args.sourcemap,
        recipient: args.recipient,
      }),
    })
      .then((response) => {
        if (response.ok) {
          resolve(response.json());
        } else {
          reject(new Error(`Request failed with status: ${response.status}`));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
