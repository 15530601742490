import Text from "../../../components/Text";

const ConfirmSignUp = {
  Header() {
    return (
      <Text
        style={{
          width: "100%",
          textAlign: "center",
          fontSize: "1.5rem",
          marginBottom: "2%",
        }}
      >
        Confirmation Code
      </Text>
    );
  },
};
export default ConfirmSignUp;
