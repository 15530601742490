export const getItemLocalDB = (key: string) => {
  const jsonValue = localStorage.getItem(key);
  if (jsonValue) {
    return JSON.parse(jsonValue);
  }
};

export const setItemLocalDB = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeItemLocalDB = (key: string) => {
  localStorage.removeItem(key);
};

export const clearLocalDB = () => {
  localStorage.clear();
};
