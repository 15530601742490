import { tCheckBoxProps } from "../../resources/types/components/tCheckBoxProps";
import Text from "../../components/Text";
import styles from "./styles.module.css";
import { ReactComponent as CheckSVG } from "../../resources/assets/svg/check.svg";
import { ReactComponent as SelectedCheckSVG } from "../../resources/assets/svg/selected-check.svg";

const CheckBox = ({
  onPress,
  label,
  children,
  selected = false,
}: tCheckBoxProps) => {
  const checkViewStyle = selected
    ? `${styles.checkView} ${styles.selected}`
    : `${styles.checkView} ${styles.unselected}`;
  return (
    <div onClick={onPress} className={styles.container}>
      <div className={checkViewStyle}>
        {!selected ? <CheckSVG width={12} height={8} /> : <SelectedCheckSVG />}
      </div>
      <Text className={styles.label}>{label}</Text>
      {children}
    </div>
  );
};

export default CheckBox;
