import { useAuthenticator } from "@aws-amplify/ui-react";
import Text from "../../../components/Text";
import styles from "./styles.module.css";
import SocialProviders from "../../../components/SocialProviders";

const SignIn = {
  Header() {
    return (
      <Text className={styles.title}>
        Make an archive for your greetings and receive a notification once your
        QR code is read
      </Text>
    );
  },
  Footer() {
    const { toForgotPassword } = useAuthenticator();
    return (
      <div className={styles.footer}>
        <Text onPress={toForgotPassword} className={styles.forgot}>
          Forgot password?
        </Text>
        <Text className={styles.textFooter}>Or sign in with</Text>
        <SocialProviders />
      </div>
    );
  },
};
export default SignIn;
