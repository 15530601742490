import { tTextProps } from "../../resources/types/components/tTextProps";
import "./styles.css";

const Text = ({ children, onPress, style, className }: tTextProps) => {
  return (
    <div onClick={onPress} className={`text ${className}`} style={style}>
      {children}
    </div>
  );
};
export default Text;
