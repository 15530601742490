import { tColors } from "../types/theme/tColors";

export const colors: tColors = {
  primary: "#CE28B7",
  secondary: "#2B2F37",
  variant_primary: "#CE28B788",
  variant_secondary: "#2b2f3788",
  black: "#000106",
  white: "#FFFFFF",
  red: "#DA1E28",
  blue: "#0000EE",
};
